export default function Division(props) {
  return (
    <hr className={props.className}
      style={{
        background: 'black',
        color: 'black',
        borderColor: 'black',
        height: '3px',
      }}
    />);
}